import React from 'react';

const QuickContact = ({type, url}) => {
    return (
        <li>
            <span>{type}:</span>{url}
        </li>
    );
};

export default QuickContact;