import React from 'react'
import FadeIn from "react-fade-in";
//import Lottie from "react-lottie";
import {Container} from 'react-bootstrap'
import ReactLoading from "react-loading";
//import * as legoData from "../../data/CustomLoading/LegoLoading.json";
//import * as doneData from "../../data/CustomLoading/DoneLoading.json";

const CustomLoading = ({status}) => {
    return (
        <FadeIn>
            <Container>
              <center>
                {/* <h1>fetching pizza</h1>
                {!status ? (
                  <Lottie options={{...defaultOptions}} height={120} width={120} />
                ) : (
                  <Lottie options={{...defaultOptions2}} height={120} width={120} />
                )} */}
                <ReactLoading type={"bars"} color={"black"} />
              </center>
            </Container>
          </FadeIn>
    )
}

// const defaultOptions = {
//     height: '100%',
//     width: '100%',
//     loop: true,
//     autoplay: true,
//     animationData: legoData.default,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice"
//     }
//   };

// const defaultOptions2 = {
//     loop: false,
//     autoplay: true,
//     animationData: doneData.default,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice"
//     }
//   };

  export default CustomLoading;