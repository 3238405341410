import API from '../../../config/services'

//production ---> "start": "serve -s build",
//local ---> "start": "react-scripts start",

export const getDataAbout = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({type:"CHANGE_LOADING", value: true})
        API.postCommand('/api/postDataPersonal', data)
            .then(res=>{
                let statuscode = res.data.statuscode;
                let dataAbout = Object.create(null);
                if(statuscode===1) {
                    let dt = [];
                    dt = res.data.data;
                    dt.map((user, index)=> {
                        return (
                            dataAbout = {
                                address: (dt[index].address),
                                name: (dt[index].name),
                                nationality: (dt[index].nationality),
                                email: (dt[index].email),
                                birthdate: (dt[index].birthdate),
                                description: (dt[index].description)
                            }
                        )
                    })
                } 
                dispatch({type:"CHANGE_LOADING", value: false})
                resolve(dataAbout)
            })
            .catch(function(error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage)
                dispatch({type:"CHANGE_LOADING", value: false})
                reject(false)
        })
})
}

export const getDataSocmed = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({type:"CHANGE_LOADING", value: true})
        API.postCommand('/api/postDataSocmed', data)
            .then(res=>{
                let statuscode = res.data.statuscode;
                let dataSocmed = [];
                if(statuscode===1) {
                    dataSocmed = res.data.data
                } 
                dispatch({type:"CHANGE_LOADING", value: false})
                resolve(dataSocmed)
            })
            .catch(function(error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage)
                dispatch({type:"CHANGE_LOADING", value: false})
                reject(false)
        })
})
}

export const getDataSkill = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({type:"CHANGE_LOADING", value: true})
        API.postCommand('/api/postDataSkill', data)
            .then(res=>{
                let statuscode = res.data.statuscode;
                let dataSkill = [];
                if(statuscode===1) {
                    dataSkill = res.data.data
                } 
                dispatch({type:"CHANGE_LOADING", value: false})
                resolve(dataSkill)
            })
            .catch(function(error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage)
                dispatch({type:"CHANGE_LOADING", value: false})
                reject(false)
        })
})
}