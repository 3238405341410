const initialState = {
    isLoading: false
}

const reducer = (state=initialState, action) => {
    if (action.type==='CHANGE_LOADING'){
        return {
            ...state,
            isLoading: action.value
        }
    }
    return state;
}

export default reducer;