import React, { Component } from 'react';
import { connect } from 'react-redux';
import { encrypt } from '../../config/lib';
import {Container, Row, Col} from 'react-bootstrap'
import { getDataAbout, getDataSkill } from '../../config/redux/action'
import Thumbnail from "../../components/thumbnail";
import Content from "../../components/content";
import Skill from "../../components/skill";
import CustomLoading from "../../components/CustomLoading"
import aboutData from '../../data/AboutMe'

class AboutMe extends Component {
    state = {
        description: '',
        skill: [],
        doneStatus: undefined,
        loadingStatus: undefined
    }

    async componentDidMount() {
        await this.loadDataAbout()
        await this.loadDataSkill()
    }
    
    loadDataAbout = async() => {
        this.setState({loadingStatus: true})
        let encryptedkuncika = encrypt("silvesteraprilianta")
        const resDataAbout = await this.props.getDataAbout({kuncika:encryptedkuncika}).catch (err => err)
        if (resDataAbout) {
            this.setState({description: resDataAbout.description, doneStatus: false})
        }
    }

    loadDataSkill = async() => {
        this.setState({loadingStatus: true})
        let encryptedkuncika = encrypt("silvesteraprilianta")
        const resDataSkill = await this.props.getDataSkill({kuncika:encryptedkuncika}).catch (err => err)
        if (resDataSkill) {
            this.setState({skill: resDataSkill, doneStatus: true})
        }
    }

    render(){
        return (
            <div className={this.props.type !== 'page' ? 'section-padding section' : null}>
                {
                    (!this.state.doneStatus) ?
                        <div style={{alignContent:'center'}}>
                            <CustomLoading status={true}></CustomLoading>
                        </div>
                    :
                        <Container>
                            <Row className={'align-items-center'}>
                                <Col lg={6}>
                                    <Thumbnail classes={'about-thumbnail mb-md-30 mb-sm-30 mb-xs-30'} thumb={`about/${aboutData.thumb}`}/>
                                </Col>

                                <Col lg={6}>
                                    <Content classes={'about-content'}>
                                        {/* {this.props.type !== 'page' ? (<h3 className="block-title">ABOUT ME</h3>) : null} */}
                                        <h3 className="block-title">ABOUT ME</h3>
                                        {/* <p>{aboutData.bio}</p> */}
                                        <p>{this.state.description}</p>
                                        {/* <ul className="personal-info">
                                            { <li><span>Skype:</span>{aboutData.skype}</li> }
                                            <li><span>Freelance:</span>{aboutData.availability ? 'Available' : 'Not Available'}</li>
                                            <li>
                                                <span>Language:</span>
                                                {aboutData.languages.map((language, index) => (
                                                    <p key={index} style={{display: 'inline-block'}}>{`${language} & `}</p>
                                                ))}
                                            </li>
                                        </ul> */}

                                        <h3 className="block-title">Skill</h3>

                                        <div className="skill-wrap">
                                            {this.state.skill.map(dt => (
                                                <Skill
                                                    key = {dt.id}
                                                    title = {dt.name}  
                                                    percentage = {dt.value}  
                                                />
                                            ))}
                                        </div>
                                    </Content>
                                </Col>
                            </Row>
                        </Container>
                }
            </div>
        )
    }
}

const reduxDispatch = (dispatch) => ({
    getDataAbout: (data) => dispatch(getDataAbout(data)),
    getDataSkill: (data) => dispatch(getDataSkill(data))
})

export default connect (null, reduxDispatch) (AboutMe);