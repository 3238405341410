import React, { Component } from 'react';
import { connect } from 'react-redux';
import { encrypt } from '../../config/lib';
import {Container, Row, Col} from 'react-bootstrap'
import { getDataAbout, getDataSocmed } from '../../config/redux/action'
//import Form from "../../components/form";
import QuickContact from "../../components/QuickContact";
import CustomLoading from "../../components/CustomLoading"
import Map from "../../components/map";

class Contact extends Component {
    state = {
        email: '',
        socmed: [],
        doneStatus: undefined,
        loadingStatus: undefined
    }

    async componentDidMount() {
        await this.loadDataAbout()
        await this.loadDataSocmed()
    }
    
    loadDataAbout = async() => {
        this.setState({loadingStatus: true})
        let encryptedkuncika = encrypt("silvesteraprilianta")
        const resDataAbout = await this.props.getDataAbout({kuncika:encryptedkuncika}).catch (err => err)
        if (resDataAbout) {
            this.setState({email: resDataAbout.email, doneStatus: false})
        }
    }

    loadDataSocmed = async() => {
        this.setState({loadingStatus: true})
        let encryptedkuncika = encrypt("silvesteraprilianta")
        const resDataSocmed = await this.props.getDataSocmed({kuncika:encryptedkuncika}).catch (err => err)
        if (resDataSocmed) {
            this.setState({socmed: resDataSocmed, doneStatus: true})
        }
    }

    render() {
        return(
            <div className="bg-grey section ">
                <div className="section-wrap section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
                    {
                        (!this.state.doneStatus) ?
                            <div style={{alignContent:'center'}}>
                                <CustomLoading status={true}></CustomLoading>
                            </div>
                        :
                            <Container>
                                <Row>
                                    <Col lg={6} className={'mb-sm-45 mb-xs-45'}>
                                        <Row>
                                            {/* <Col lg={10} className={'mb-45'}>
                                                <div className="contact-form">
                                                    <h3>CONTACT FORM</h3>
                                                    <Form/>
                                                </div>
                                            </Col> */}
            
                                            <Col lg={10}>
                                                <div className="contact-info">
                                                    <h3>QUICK CONTACT</h3>
                                                    <ul>
                                                        {this.state.socmed.map(dt => (
                                                            <QuickContact
                                                                key = {dt.id}
                                                                type = {dt.type_socmed}  
                                                                url = {dt.url}  
                                                            />
                                                        ))}
                                                        <QuickContact
                                                            type = 'Email'
                                                            url = {this.state.email}  
                                                        />
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                    
                                    <Col lg={6}  className={'mb-sm-45 mb-xs-45'}>
                                        <Row>
                                            <Col lg={10}>
                                                <div className="contact-info">
                                                    <h3>ASK ME ANYTHING 24/7</h3>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Map
                                            text={'Ask me anything 24/7'}
                                            lat={-7.782648334177445}
                                            long={110.36694410918362}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                    }
                </div>
            </div>
        )
    }
}

const reduxDispatch = (dispatch) => ({
    getDataAbout: (data) => dispatch(getDataAbout(data)),
    getDataSocmed: (data) => dispatch(getDataSocmed(data))
})

export default connect (null, reduxDispatch) (Contact);